// extracted by mini-css-extract-plugin
export var mediaTab = "style-mod--media-tab--1KIMw";
export var mediaTabContainer = "style-mod--media-tab-container--1lhh-";
export var mediaTabButtons = "style-mod--media-tab-buttons--osluK";
export var mediaTabButtonsItem = "style-mod--media-tab-buttons-item--3sQly";
export var buttonActive = "style-mod--button-active---A2uY";
export var mediaTabButtonsDesktop = "style-mod--media-tab-buttons-desktop--GpZX8";
export var mediaTabButtonsMobile = "style-mod--media-tab-buttons-mobile--17aCb";
export var mediaTabItem = "style-mod--media-tab-item--chNB2";
export var tabActive = "style-mod--tab-active--25oEV";
export var mediaTabText = "style-mod--media-tab-text--27Mnn";
export var mediaTabImage = "style-mod--media-tab-image--1ik66";
export var mediaTabVideo = "style-mod--media-tab-video--35XBT";
export var buttonClicked = "style-mod--button-clicked--FV7Bs";
export var mediaTabItemReverse = "style-mod--media-tab-item-reverse--1I-Gp";